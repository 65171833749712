import Box from "../../../../common/components/Box";
import Heading from "../../../../common/components/Heading";
import Text from "../../../../common/components/Text";
import Container from "../../../../common/components/UI/Container";
import { SECTION_CONTACT } from "../../../../common/data/WebApp";
import { Link } from "gatsby";
import React, { useRef } from "react";
import { Icon } from "react-icons-kit";
import emailjs from "@emailjs/browser";
import { androidArrowForward } from "react-icons-kit/ionicons/androidArrowForward";
import SectionArea, {
  Row,
  Input,
  TextArea,
  Submit,
  ContactFormContainer,
  Col,
  TopHeading,
  ColumnContents,
} from "./section.style";

const ContactForm = () => {
  const { sections } = SECTION_CONTACT;
  const [open, setOpen] = React.useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1m6xh6r",
        "template_ejz576n",
        form.current,
        "IsW6onuLEf6RgIfJ4"
      )
      .then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setOpen(true);
          }
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <SectionArea>
      <Container>
        <Row className="rowContents">
          <Box className="blockTitle">
            <Heading as="h2" content="Get in touch with us" />
            <Text
              as="p"
              content="To learn more about our solutions and services, or how we can help your business - get in touch with us."
            />
          </Box>
          {!open ? (
            <Box className="blockTitle">
              <ContactFormContainer>
                <form id="contact-form" ref={form} onSubmit={sendEmail}>
                  <Input
                    placeholder="Enter your name"
                    type="text"
                    name="user_name"
                  />

                  <Input
                    placeholder="Enter your e-mail"
                    type="email"
                    name="user_email"
                  />
                  <TextArea
                    placeholder="What would you like to know more about..."
                    name="message"
                  />
                  <Submit value="Send" />
                </form>
              </ContactFormContainer>
            </Box>
          ) : (
            <Box className="blockTitle">
              <ContactFormContainer>
                <Heading as="h2" content="Message Sent" />
                <Text
                  as="p"
                  content="Thanks for your message, we will be in contact."
                />
              </ContactFormContainer>
            </Box>
          )}
        </Row>
      </Container>
    </SectionArea>
  );
};

export default ContactForm;
